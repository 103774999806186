.layout-footer {
    @include transition(margin-left $transitionDuration);
    background-color: $footerBgColor;
    padding: 1em 2em;
    img {
        vertical-align: middle;
    }
    .footer-text {
        color: $VWAGBLUEDARK;
        text-decoration: none;
        vertical-align: middle;
    }
}